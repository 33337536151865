<template>
  <div class="min-h-48">
    <p class="text-2xl font-bold mb-4">Step 2 : Select Value</p>
    <div class="border border-neutral-250 rounded-lg px-4 pt-4 pb-6 mb-5">
      <div class="mb-3 w-1/2">
        <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Template Name</label>
        <TextField type="text" v-model="templateNameProps" borderEnabled placeholder="Input template name here..." />
      </div>
      <div v-for="(value, index) in valueListProps" :key="index" class="mb-3 flex items-center">
        <div class="w-1/2">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Value {{ index + 1 }}</label>
          <Dropdown
            :options="optionValue[index]"
            :default="valueListProps[index]"
            :disabled="valueListProps.length > index + 1"
            optionLabel="name"
            v-model="valueListProps[index]"
            placeholder="Select value"
            @change="changeValue(value, index)"
          />
        </div>
        <div class="ml-3 mt-5 rounded-lg bg-yellow-secondary p-2 cursor-pointer" v-if="valueListProps.length > 1 && index + 1 === valueListProps.length" @click="deleteValue(index, value)">
          <Trash color="yellow" />
        </div>
      </div>
      <div class="flex justify-center items-center mt-5 w-1/2">
        <Button buttonText="Add New Value" :disabled="isAddNewValueButtonDisabled" type="secondary" @action="addValue" additionalClass="" />
      </div>
    </div>
    <div class="flex justify-center items-center pt-4">
      <Button buttonText="Save" :disabled="isSaveButtonDisabled" @action="saveValueList" additionalClass="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'choose-file',
  props: {
    templateName: {
      type: String,
      default: ''
    },
    valueList: {
      type: Array,
      default: () => []
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    reloadData: {
      type: Function,
      default: () => {}
    },
    valueListEdit: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      valueBucket: [],
      channgeValueItem: {},
      optionValue: [
        [
          {
            name: 'Student Name',
            id: 'name'
          },
          {
            name: 'Post Test Score',
            id: 'score'
          },
          {
            name: 'Post Test Date',
            id: 'certificateDate'
          },
          {
            name: 'Publish Date',
            id: 'publishDate'
          },
          {
            name: 'Course Title',
            id: 'courseTitle'
          },
          {
            name: 'Certificate Number',
            id: 'certificateNumber'
          },
          {
            name: 'QR Code',
            id: 'qrcode'
          }
        ]
      ],
      optionList: [
        {
          name: 'Student Name',
          id: 'name'
        },
        {
          name: 'Post Test Score',
          id: 'score'
        },
        {
          name: 'Post Test Date',
          id: 'certificateDate'
        },
        {
          name: 'Publish Date',
          id: 'publishDate'
        },
        {
          name: 'Course Title',
          id: 'courseTitle'
        },
        {
          name: 'Certificate Number',
          id: 'certificateNumber'
        },
        {
          name: 'QR Code',
          id: 'qrcode'
        }
      ]
    }
  },
  created() {
    if (this.isEditMode) {
      this.optionValue = []
      this.valueBucket = this.valueListEdit
      const valueListId = []
      this.valueListProps.map((v, i) => {
        if (i === 0) {
          this.optionValue.push(this.optionList)
        } else {
          valueListId.push(this.valueListProps[i - 1].id)
          const option = this.optionList.filter((option) => !valueListId.includes(option.id))
          this.optionValue.push(option)
        }
      })
    }
  },
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close'),
    ThreeDots: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/ThreeDots'),
    Trash: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Trash'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  computed: {
    templateNameProps: {
      get() {
        return this.templateName
      },
      set(value) {
        this.$emit('input', value, 'templateName')
      }
    },
    valueListProps: {
      get() {
        return this.valueList
      },
      set(value) {
        this.$emit('input', value, 'valueList')
      }
    },
    isSaveButtonDisabled() {
      if (this.templateName === '') return true
      if (this.isAddNewValueButtonDisabled) return true
      return false
    },
    isAddNewValueButtonDisabled() {
      if (!this.valueListProps[0]) return true
      if (!this.valueListProps[this.valueListProps.length - 1]) return true
      return false
    }
  },
  methods: {
    changeValue(value, index) {
      if (value === null) {
        const item = {
          code: this.valueListProps[index].id,
          certificate_template_id: 128,
          location_x: 108,
          location_y: 230,
          field_length: 387,
          centered_horizontally: false,
          text_color: 'BLACK',
          text_size: 24,
          image_width: 0,
          image_height: 0
        }
        this.valueBucket.push(item)
        return
      }
      const deletedItemId = this.valueBucket.filter((item) => item.code === value.id)[0].id

      if (deletedItemId) {
        return this.$store
          .dispatch('certificate/DELETE_CERTIFICATE_ITEMS', {
            id: deletedItemId
          })
          .then(() => {
            const item = {
              code: this.valueListProps[index].id,
              certificate_template_id: 128,
              location_x: 108,
              location_y: 230,
              field_length: 387,
              centered_horizontally: false,
              text_color: 'BLACK',
              text_size: 24,
              image_width: 0,
              image_height: 0
            }
            this.valueBucket = this.valueBucket.filter((item) => item.code !== value.id)
            this.valueBucket.push(item)
          })
      }

      const item = {
        code: this.valueListProps[index].id,
        certificate_template_id: 128,
        location_x: 108,
        location_y: 230,
        field_length: 387,
        centered_horizontally: false,
        text_color: 'BLACK',
        text_size: 24,
        image_width: 0,
        image_height: 0
      }
      this.valueBucket = this.valueBucket.filter((item) => item.code !== value.id)
      this.valueBucket.push(item)
    },
    sliceWords(str, limit) {
      if (str.length > limit) return str.slice(0, limit) + '...'
      return str
    },
    addValue() {
      const valueListId = this.valueListProps.map((value) => value.id)
      const option = this.optionList.filter((option) => !valueListId.includes(option.id))
      this.optionValue.push(option)
      this.valueListProps.push(null)
    },
    deleteValue(index) {
      if (this.isEditMode && this.valueBucket[index].id) {
        return this.$store
          .dispatch('certificate/DELETE_CERTIFICATE_ITEMS', {
            id: this.valueBucket[index].id
          })
          .then(() => {
            this.reloadData()
            this.valueListProps = this.valueListProps.filter((value, i) => i !== index)
            this.optionValue.splice(index, 1)
          })
      }

      this.valueListProps = this.valueListProps.filter((value, i) => i !== index)
      this.valueBucket.splice(index, 1)
      this.optionValue.splice(index, 1)
    },
    saveValueList() {
      const valueList = this.valueListProps.map((value) => value.id)
      this.$emit('save', this.templateName, valueList, this.valueBucket)
    }
  }
}
</script>

<style></style>
