var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-48"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-4"
  }, [_vm._v("Step 2 : Select Value")]), _c('div', {
    staticClass: "border border-neutral-250 rounded-lg px-4 pt-4 pb-6 mb-5"
  }, [_c('div', {
    staticClass: "mb-3 w-1/2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Template Name")]), _c('TextField', {
    attrs: {
      "type": "text",
      "borderEnabled": "",
      "placeholder": "Input template name here..."
    },
    model: {
      value: _vm.templateNameProps,
      callback: function callback($$v) {
        _vm.templateNameProps = $$v;
      },
      expression: "templateNameProps"
    }
  })], 1), _vm._l(_vm.valueListProps, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3 flex items-center"
    }, [_c('div', {
      staticClass: "w-1/2"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Value " + _vm._s(index + 1))]), _c('Dropdown', {
      attrs: {
        "options": _vm.optionValue[index],
        "default": _vm.valueListProps[index],
        "disabled": _vm.valueListProps.length > index + 1,
        "optionLabel": "name",
        "placeholder": "Select value"
      },
      on: {
        "change": function change($event) {
          return _vm.changeValue(value, index);
        }
      },
      model: {
        value: _vm.valueListProps[index],
        callback: function callback($$v) {
          _vm.$set(_vm.valueListProps, index, $$v);
        },
        expression: "valueListProps[index]"
      }
    })], 1), _vm.valueListProps.length > 1 && index + 1 === _vm.valueListProps.length ? _c('div', {
      staticClass: "ml-3 mt-5 rounded-lg bg-yellow-secondary p-2 cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.deleteValue(index, value);
        }
      }
    }, [_c('Trash', {
      attrs: {
        "color": "yellow"
      }
    })], 1) : _vm._e()]);
  }), _c('div', {
    staticClass: "flex justify-center items-center mt-5 w-1/2"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add New Value",
      "disabled": _vm.isAddNewValueButtonDisabled,
      "type": "secondary",
      "additionalClass": ""
    },
    on: {
      "action": _vm.addValue
    }
  })], 1)], 2), _c('div', {
    staticClass: "flex justify-center items-center pt-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Save",
      "disabled": _vm.isSaveButtonDisabled,
      "additionalClass": ""
    },
    on: {
      "action": _vm.saveValueList
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }